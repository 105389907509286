import { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import "./CarouselBanner.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MissingContent from "../errorMessages/MissingContent";

function CarouselBanner({ images, video }: any) {
  const initialItems = video ? [{ url: video, isVideo: true }, ...images] : [...images];
  const [items, setItems] = useState<any[]>(initialItems);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    // Update items only if images or video props change after initial render
    const newItems = video ? [{ url: video, isVideo: true }, ...images] : [...images];
    setItems(newItems);
  }, [images, video]);

  const next = () => {
    setCurrentIndex((currentIndex + 1) % items.length);
  };

  const previous = () => {
    setCurrentIndex((currentIndex - 1 + items.length) % items.length);
  };

  useEffect(() => {
    items.forEach((item: any) => {
      if (item.isVideo) {
        const video = document.createElement("video");
        video.src = item.url;
      } else {
        const img = new Image();
        img.src = item.url;
      }
    });
  }, [items]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (items[currentIndex]?.isVideo && videoRef.current) {
        videoRef.current.play();
      } else {
        next();
      }
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, [currentIndex, items]);

  // Automatically move to the next item when the video ends
  const handleVideoEnd = () => {
    next();
  };

  return (
    <Box>
      {items.length === 0 ? (
        <MissingContent sectionName="Banner Carousel" />
      ) : (
        <Box
          sx={{
            width: "100%",
            position: "relative",
            overflow: "hidden",
            paddingTop: "56.25%", // Note for devs: aspect ratio of 16:9 hack
          }}
        >
          {items.map((item, index) => (
            <Box
              key={index}
              className={`slide ${index === currentIndex ? "active" : ""}`}
              sx={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              {item.isVideo ? (
                <video
                  ref={videoRef}
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    objectFit: "contain",
                  }}
                  onEnded={handleVideoEnd}
                  muted
                  autoPlay
                  playsInline
                >
                  <source src={item.url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    objectFit: "contain",
                  }}
                  src={item.url}
                  alt=""
                />
              )}
            </Box>
          ))}
          {items.length > 1 && (
            <>
              <ArrowBackIosIcon
                sx={{
                  position: "absolute",
                  bottom: "50%",
                  left: "5%",
                  zIndex: 1,
                  cursor: "pointer",
                }}
                onClick={previous}
              />
              <ArrowForwardIosIcon
                sx={{
                  position: "absolute",
                  bottom: "50%",
                  right: "5%",
                  zIndex: 1,
                  cursor: "pointer",
                }}
                onClick={next}
              />
            </>
          )}
        </Box>
      )}
    </Box>
  );
}

export default CarouselBanner;
