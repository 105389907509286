// FilterComponent.tsx

import { useEffect, useState, useRef } from 'react';
import { apiFilterProducts } from '../../../api/apiMultiFilter';
import ProductGrid from './components/ProductGrid';
import FilterSelection from './components/FilterSelection';
import ApplicationSelection from './components/ApplicationSelection';
import ClearFilters from './components/ClearFilters';
import { Grid, Box, Typography, Container } from '@mui/material';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

function FilterComponent({ itemGroup, title }: any) {
    const navigate = useNavigate();
    const location = useLocation();

    const [availableApplications, setAvailableApplications] = useState([]);
    const [applicationsList, setApplicationsList] = useState<string[]>([]);

    const [availableColours, setAvailableColours] = useState([]);
    const [colourList, setColourList] = useState<string[]>([]);
    const [availableDesigns, setAvailableDesigns] = useState([]);
    const [designList, setDesignList] = useState<string[]>([]);
    const [availableTypes, setAvailableTypes] = useState([]);
    const [typesList, setTypesList] = useState<string[]>([]);
    const [productItemCount, setProductItemCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 9;
    const [totalPages, setTotalPages] = useState<number>(0);

    const anchorRef = useRef<HTMLDivElement>(null); // Properly typed ref

    const getProductsMutation: any = useMutation(
        (filters: any) => {
            return apiFilterProducts(filters);
        },
        {
            onSuccess: (data) => {
                const lastItem = data[data.length - 1];
                setAvailableApplications(lastItem?.applications || []);
                setAvailableColours(lastItem?.web_colours || []);
                setAvailableDesigns(lastItem?.web_designs_style || []);
                setAvailableTypes(lastItem?.webtypes || []);
                setProductItemCount(lastItem?.total_items_count || 0);

                const pages = Math.ceil((lastItem?.total_items_count || 0) / itemsPerPage);
                setTotalPages(pages);
            },
        }
    );

    // Handle checkbox changes and update state variables only
    const handleCheckboxChange =
        (
            elem: string | number,
            setList: React.Dispatch<React.SetStateAction<string[]>>,
            list: string[],
            paramKey: string
        ) =>
            (event: React.ChangeEvent<HTMLInputElement>) => {
                const isChecked = event.target.checked;
                const elemStr = elem.toString();

                let updatedList: string[];

                if (isChecked) {
                    updatedList = [...list, elemStr];
                    setList(updatedList);
                } else {
                    updatedList = list.filter((item) => item !== elemStr);
                    setList(updatedList);
                }
            };

    // Function to apply filters when the Apply button is clicked
    const applyFilters = () => {
        const currentParams = new URLSearchParams();

        // Append all selected filters to the URL parameters
        applicationsList.forEach((app) => currentParams.append('application', app));
        colourList.forEach((col) => currentParams.append('colours', col));
        designList.forEach((design) => currentParams.append('design_style', design));
        typesList.forEach((type) => currentParams.append('types', type));

        // Reset pagination parameters
        currentParams.set('page', '1');
        currentParams.set('startoffset', '0');
        currentParams.set('endoffset', itemsPerPage.toString());

        // Update the URL with the new parameters
        navigate(`${location.pathname}?${currentParams.toString()}`, { replace: true });

        // Update the current page state
        setCurrentPage(1);

        // Scroll to the top of the FilterComponent
        if (anchorRef.current) {
            window.scrollTo({
                top: anchorRef.current.offsetTop - 65, // Adjust the offset as needed
                behavior: 'smooth',
            });
        }
    };

    const getInitialData = () => {
        const currentParams = new URLSearchParams(location.search);

        const applicationParams = currentParams.getAll('application');
        const colourParams = currentParams.getAll('colours');
        const designParams = currentParams.getAll('design_style');
        const typeParams = currentParams.getAll('types');
        const pageParam = currentParams.get('page');
        const startOffsetParam = currentParams.get('startoffset');
        const endOffsetParam = currentParams.get('endoffset');

        const page = pageParam ? parseInt(pageParam) : 1;
        const startOffset = startOffsetParam ? parseInt(startOffsetParam) : 0;
        const endOffset = endOffsetParam ? parseInt(endOffsetParam) : itemsPerPage;

        // Set the state with parameters from the URL
        setApplicationsList(applicationParams);
        setColourList(colourParams);
        setDesignList(designParams);
        setTypesList(typeParams);

        // Fetch products with the filters from the URL
        getProductsMutation.mutate({
            filter: {
                item_group: itemGroup,
            },
            off_setter: {
                start: startOffset,
                end: endOffset,
            },
            required_fields: {
                item_group: itemGroup,
                Applications: applicationParams,
                WebTypes: typeParams.map(Number),
                WebColors: colourParams.map(Number),
                WebDesignStyles: designParams.map(Number),
            },
        });

        setCurrentPage(page);
    };

    const clearFilters = () => {
        setApplicationsList([]);
        setColourList([]);
        setDesignList([]);
        setTypesList([]);

        // Clear URL parameters
        navigate(`${location.pathname}`, { replace: true });

        // Optionally, reset pagination if needed
        setCurrentPage(1);

        // Scroll to the top of the FilterComponent
        if (anchorRef.current) {
            window.scrollTo({
                top: anchorRef.current.offsetTop - 65,
                behavior: 'smooth',
            });
        }

        // Re-fetch data without filters
        getProductsMutation.mutate({
            filter: {
                item_group: itemGroup,
            },
            off_setter: {
                start: 0,
                end: itemsPerPage,
            },
            required_fields: {
                item_group: itemGroup,
                Applications: [],
                WebTypes: [],
                WebColors: [],
                WebDesignStyles: [],
            },
        });
    };

    const updateOffsetParams = (params: { [key: string]: string }): void => {
        const currentParams = new URLSearchParams(location.search);

        Object.entries(params).forEach(([key, value]) => {
            currentParams.set(key, value);
        });

        navigate(`${location.pathname}?${currentParams.toString()}`, { replace: true });
    };

    // Scroll to the top of the FilterComponent
    const scrollToFilterComponent = () => {
        if (anchorRef.current) {
            window.scrollTo({
                top: anchorRef.current.offsetTop - 65, // Adjust the offset as needed
                behavior: 'smooth',
            });
        }
    };

    // Pagination functions adjusted to scroll to the top of FilterComponent
    const nextPage = () => {
        if (currentPage === totalPages) {
            return;
        } else {
            const nextPageNum = currentPage + 1;
            const startOffset = (nextPageNum - 1) * itemsPerPage;
            const endOffset = startOffset + itemsPerPage;

            updateOffsetParams({
                page: nextPageNum.toString(),
                startoffset: startOffset.toString(),
                endoffset: endOffset.toString(),
            });

            setCurrentPage(nextPageNum);

            // Scroll to the top of the FilterComponent
            scrollToFilterComponent();
        }
    };

    const previousPage = () => {
        if (currentPage === 1) {
            return;
        } else {
            const prevPageNum = currentPage - 1;
            const startOffset = (prevPageNum - 1) * itemsPerPage;
            const endOffset = startOffset + itemsPerPage;

            updateOffsetParams({
                page: prevPageNum.toString(),
                startoffset: startOffset.toString(),
                endoffset: endOffset.toString(),
            });

            setCurrentPage(prevPageNum);

            // Scroll to the top of the FilterComponent
            scrollToFilterComponent();
        }
    };

    const lastPage = () => {
        if (currentPage === totalPages) {
            return;
        } else {
            const lastPageNumber = totalPages;
            const startOffset = (lastPageNumber - 1) * itemsPerPage;
            const endOffset = startOffset + itemsPerPage;

            updateOffsetParams({
                page: lastPageNumber.toString(),
                startoffset: startOffset.toString(),
                endoffset: endOffset.toString(),
            });

            setCurrentPage(lastPageNumber);

            // Scroll to the top of the FilterComponent
            scrollToFilterComponent();
        }
    };

    const firstPage = () => {
        if (currentPage === 1) {
            return;
        } else {
            const firstPageNumber = 1;
            const startOffset = 0;
            const endOffset = itemsPerPage;

            updateOffsetParams({
                page: firstPageNumber.toString(),
                startoffset: startOffset.toString(),
                endoffset: endOffset.toString(),
            });

            setCurrentPage(firstPageNumber);

            // Scroll to the top of the FilterComponent
            scrollToFilterComponent();
        }
    };

    // Run getInitialData when the component mounts or when the URL parameters change
    useEffect(() => {
        getInitialData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]); // Listen for changes in URL parameters

    return (
        <Container maxWidth="xl">
            <Typography
                variant="h3"
                mb={1}
                mt={10}
                ml={2}
                fontWeight={400}
                fontSize={'40px'}
                textTransform={'capitalize'}
            >
                {` Discover - ${title}`}
            </Typography>
            <Typography fontWeight={400} fontSize="18px" mb={'-4rem'} ml={2}>
                Our inventory features thousands of designs. Use the selection bar below to find exactly what you’re looking for.
            </Typography>

            <Box ref={anchorRef}>
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', lg: 'row' },
                        height: 'auto',
                        width: '100%',
                        padding: '1rem',
                        m: '4rem 0',
                    }}
                >
                    <Box
                        sx={{
                            flex: { xs: '12', lg: '4' },
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            mr: { xs: 0, lg: 5 },
                        }}
                    >
                        <ClearFilters clearFilters={clearFilters} />
                        {availableApplications.length > 1 && (
                            <ApplicationSelection
                                availableApplications={availableApplications}
                                handleCheckboxChange={handleCheckboxChange}
                                applicationsList={applicationsList}
                                setApplicationsList={setApplicationsList}
                            />
                        )}

                        <FilterSelection
                            availableColours={availableColours}
                            availableDesigns={availableDesigns}
                            availableTypes={availableTypes}
                            handleCheckboxChange={handleCheckboxChange}
                            colourList={colourList}
                            designList={designList}
                            typesList={typesList}
                            setDesignList={setDesignList}
                            setColourList={setColourList}
                            setTypesList={setTypesList}
                            anchorRef={anchorRef}
                            applyFilters={applyFilters} // Pass applyFilters
                        />
                    </Box>
                    <Box
                        flex={{ xs: 12, lg: 8 }}
                        sx={{
                            height: '100%',
                            flexDirection: 'column',
                        }}
                    >
                        {getProductsMutation?.data?.length === 1 &&
                        getProductsMutation?.data[0]?.total_items_count === 0 ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    width: '100%',
                                    height: '80vh',
                                }}
                            >
                                <Typography fontSize={'1.5rem'} fontWeight={'bold'}>
                                    No items found
                                </Typography>
                                <Typography fontSize={'1.5rem'} fontWeight={'bold'}>
                                    Please try a different selection
                                </Typography>
                            </Box>
                        ) : (
                            <ProductGrid
                                productItemCount={productItemCount}
                                getProductsMutation={getProductsMutation}
                                totalPages={totalPages}
                                currentPage={currentPage}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                lastPage={lastPage}
                                firstPage={firstPage}
                                anchorRef={anchorRef}
                            />
                        )}
                    </Box>
                </Grid>
            </Box>
        </Container>
    );
}

export default FilterComponent;
