import {
    Box,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    Typography,
} from '@mui/material';

const ApplicationSelection = ({
                                  availableApplications,
                                  handleCheckboxChange,
                                  applicationsList,
                                  setApplicationsList,
                              }: any) => {
    return (
        <>
            <Divider sx={{ backgroundColor: '#000000', mb: '1rem' }} />
            <Typography fontWeight='bold' fontSize='20px' mb={2}>
                Applications
            </Typography>

            <Box sx={{ overflowY: 'auto', maxHeight: '20.5rem' }}>
                <FormGroup>
                    {availableApplications
                        ?.sort()
                        .map((elem: any, index: number) => (
                            <FormGroup key={index}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label={elem}
                                    onChange={handleCheckboxChange(
                                        elem,
                                        setApplicationsList,
                                        applicationsList,
                                        'application'
                                    )}
                                    checked={
                                        applicationsList.length === 0
                                            ? false
                                            : applicationsList.includes(elem)
                                    }
                                />
                            </FormGroup>
                        ))}
                </FormGroup>
            </Box>
        </>
    );
};

export default ApplicationSelection;
