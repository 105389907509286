import { baseURL } from "./baseUrl";

export const apiCategories = async () => {
  try {
    const response = await baseURL.get("get-all-categories");
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const apiGetCategoryData = async () => {
  try {
    const response = await baseURL.get("get-all-categories");
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const apiGetCategoryMainPageData = async (category: object) => {
  const response = await baseURL.post(`get-all-cms-data`, category, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const apiGetApplicationNamesByItemGroup = async (
  itemGroup: string
): Promise<{ appliactions: string[] }> => {
  const response = await baseURL.get(
    `products/item_group/get_all_applications/${itemGroup}`
  );
  const data = response.data as { appliactions: string[] };
  return data;
};
