// FilterSelection.tsx

import Filter from './Filter';
import { Box, Button, Divider, Typography, useMediaQuery } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect, useState } from 'react';

const FilterSelection = ({
                             availableColours,
                             availableDesigns,
                             availableTypes,
                             handleCheckboxChange,
                             colourList,
                             setColourList,
                             designList,
                             setDesignList,
                             typesList,
                             setTypesList,
                             anchorRef,
                             applyFilters, // New prop
                         }: any) => {
    const [showDesignStyle, setShowDesignStyle] = useState<boolean>(true);
    const [showColour, setShowColour] = useState<boolean>(true);
    const [showTypes, setShowTypes] = useState<boolean>(true);
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        if (isMobile) {
            setShowColour(false);
            setShowDesignStyle(false);
            setShowTypes(false);
        }
    }, [isMobile]);

    return (
        <>
            {/* Colour Filter */}
            <Divider sx={{ backgroundColor: '#000000', m: '1rem 0' }} />

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography fontWeight="bold" fontSize="20px" mb={2}>
                    Colour
                </Typography>
                <Box
                    onClick={() => {
                        setShowColour(!showColour);
                    }}
                    sx={{ cursor: 'pointer' }}
                >
                    {showColour ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                </Box>
            </Box>
            {showColour && (
                <Filter
                    filterType={availableColours}
                    handleCheckboxChange={handleCheckboxChange}
                    list={colourList}
                    setList={setColourList}
                    keyName="colours"
                    paramKey="colours"
                />
            )}

            {/* Design Filter */}
            <Divider sx={{ backgroundColor: '#000000', m: '1rem 0' }} />

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography fontWeight="bold" fontSize="20px" mb={2}>
                    Design
                </Typography>
                <Box
                    onClick={() => {
                        setShowDesignStyle(!showDesignStyle);
                    }}
                    sx={{ cursor: 'pointer' }}
                >
                    {showDesignStyle ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                </Box>
            </Box>
            {showDesignStyle && (
                <Filter
                    filterType={availableDesigns}
                    handleCheckboxChange={handleCheckboxChange}
                    list={designList}
                    setList={setDesignList}
                    keyName="design_style"
                    paramKey="design_style"
                />
            )}

            {/* Types Filter */}
            <Divider sx={{ backgroundColor: '#000000', m: '1rem 0' }} />

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography fontWeight="bold" fontSize="20px" mb={2}>
                    Types
                </Typography>
                <Box
                    onClick={() => {
                        setShowTypes(!showTypes);
                    }}
                    sx={{ cursor: 'pointer' }}
                >
                    {showTypes ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                </Box>
            </Box>
            {showTypes && (
                <Filter
                    filterType={availableTypes}
                    handleCheckboxChange={handleCheckboxChange}
                    list={typesList}
                    setList={setTypesList}
                    keyName="types"
                    paramKey="types"
                />
            )}

            {/* Apply Button */}
            <Divider sx={{ backgroundColor: '#000000', m: '1rem 0' }} />

            <Button
                variant="contained"
                onClick={() => {
                    // Apply Filters
                    applyFilters();
                }}
                sx={{
                    width: { xs: '100%', lg: '50%' },
                }}
                disableElevation
            >
                Apply
            </Button>

            <Divider sx={{ backgroundColor: '#000000', m: '1rem 0' }} />
        </>
    );
};

export default FilterSelection;
