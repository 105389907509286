export const storeData = [
  {
    title: 'Western Cape',
    stores: [
      {
        title: 'Bellville Showroom',
        address: '12 Bella Rosa Street, Bellville, Cape Town, 7550',
        phone: '021 914 3390',
        holidayNotice: [
          'Closed for the holidays from 14h00 on 13 Dec.',
          'Re-open 6 Jan \'25.'
        ],
        tradingHours: [
          { day: 'Monday - Thursday', time: '08:00 - 17:00' },
          { day: 'Friday', time: '08:00 - 16:30' },
          { day: 'Saturday', time: '09:00 - 14:00' },
          { day: 'Sunday', time: 'Closed' },
          { day: 'Public Holidays', time: 'Closed' },
        ],
        mapUrl:
            'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.846325815717!2d18.636311934887694!3d-33.86785109999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc50b62bf8da53%3A0xae9580df5132c473!2sHertex%20Fabrics%20Bellville%20Showroom!5e0!3m2!1sen!2sza!4v1718111953634!5m2!1sen!2sza',
      },
      {
        title: 'Cape Town Showroom',
        address: '187 Upper Buitenkant Street, Gardens, Cape Town, 8001',
        phone: '021 461 7420',
        holidayNotice: [
          'Closed for the holidays from 14h00 on 13 Dec.',
          'Re-open 6 Jan \'25.'
        ],
        tradingHours: [
          { day: 'Monday - Thursday', time: '08:00 - 17:00' },
          { day: 'Friday', time: '08:00 - 16:30' },
          { day: 'Saturday', time: '09:00 - 14:00' },
          { day: 'Sunday', time: 'Closed' },
          { day: 'Public Holidays', time: 'Closed' },
        ],
        mapUrl:
            'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3310.16169883397!2d18.421175!3d-33.936969!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc67787d443817%3A0xdaaa26021e00ff2e!2sHertex%20Fabrics%20Cape%20Town%20Showroom!5e0!3m2!1sen!2sza!4v1718112141146!5m2!1sen!2sza',
      },
      {
        title: 'George Showroom',
        address: '85 Meade St, George, Western Cape, 6530',
        phone: '044 884 1501',
        holidayNotice: [
          'Closed for the holidays from 14h00 on 13 Dec.',
          'Re-open 6 Jan \'25.'
        ],
        tradingHours: [
          { day: 'Monday - Thursday', time: '08:00 - 17:00' },
          { day: 'Friday', time: '08:00 - 16:30' },
          { day: 'Saturday', time: '09:00 - 13:00' },
          { day: 'Sunday', time: 'Closed' },
          { day: 'Public Holidays', time: 'Closed' },
        ],
        mapUrl:
            'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d26470.811932824203!2d22.439328605157485!3d-33.97065638857357!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dd605345ee930ab%3A0x7ea09c2f47129b1f!2sHertex%20Fabrics%20George%20Showroom!5e0!3m2!1sen!2sza!4v1718116042994!5m2!1sen!2sza',
      },
      {
        title: 'Somerset West Showroom',
        address: '10 Bright St, Somerset West, Cape Town, 7130',
        phone: '021 852 8152',
        holidayNotice: [
          'Closed for the holidays from 14h00 on 13 Dec.',
          'Re-open 6 Jan \'25.'
        ],
        tradingHours: [
          { day: 'Monday - Thursday', time: '08:00 - 17:00' },
          { day: 'Friday', time: '08:00 - 16:30' },
          { day: 'Saturday', time: '09:00 - 13:00' },
          { day: 'Sunday', time: 'Closed' },
          { day: 'Public Holidays', time: 'Closed' },
        ],
        mapUrl:
            'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13218.409672054979!2d18.8469647!3d-34.0797048!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcdb5280ba8f695%3A0xae4fc57961272c3b!2sHertex%20Fabrics%20Somerset%20West%20Showroom!5e0!3m2!1sen!2sza!4v1718116426017!5m2!1sen!2sza',
      },
      {
        title: 'HAUS Waterstone Store',
        address: 'Shop 33, Waterstone Village Cnr R44 &, Main Rd, Somerset West, Cape Town, 7130',
        phone: '021 879 5869',
        tradingHours: [
          { day: 'Monday - Friday', time: '09:00 - 18:00' },
          { day: 'Saturday', time: '09:00 - 17:00' },
          { day: 'Sunday', time: '09:00 - 14:00' },
          { day: 'Public Holidays', time: '09:00 - 14:00' },
          // Added the closed dates here
          { day: 'Closed on 25 Dec, 26 Dec & 1 Jan', time: '' },
        ],
        mapUrl:
            'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13219.86043076169!2d18.8235424!3d-34.0704087!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcdb5d40698e043%3A0xb2eb536127efa0f9!2sHAUS%20Waterstone%20Somerset%20Retail%20Store!5e0!3m2!1sen!2sza!4v1718112319712!5m2!1sen!2sza',
      },
      {
        title: 'Wynberg Showroom',
        address: '1 Carr Hill Rd, Wynberg, Cape Town, 7800',
        phone: '021 761 7734',
        holidayNotice: [
          'Closed for the holidays from 14h00 on 13 Dec.',
          'Re-open 6 Jan \'25.'
        ],
        tradingHours: [
          { day: 'Monday - Thursday', time: '08:00 - 17:00' },
          { day: 'Friday', time: '08:00 - 16:30' },
          { day: 'Saturday', time: '09:00 - 13:00' },
          { day: 'Sunday', time: 'Closed' },
          { day: 'Public Holidays', time: 'Closed' },
        ],
        mapUrl:
            'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3307.52996742202!2d18.459036634887696!3d-34.004605000000005!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc42e5610bdaab%3A0x31beb279d76e0aa1!2sHertex%20Fabrics%20Wynberg%20Showroom!5e0!3m2!1sen!2sza!4v1718112430017!5m2!1sen!2sza',
      },
    ],
  },
  {
    title: 'Eastern Cape',
    stores: [
      {
        title: 'Port Elizabeth Showroom',
        address: 'Cnr 45 Prospect Rd, Walmer, Gqeberha, 6064',
        phone: '041 373 2887',
        holidayNotice: [
          'Closed for the holidays from 14h00 on 13 Dec.',
          'Re-open 6 Jan \'25.'
        ],
        tradingHours: [
          { day: 'Monday - Thursday', time: '08:00 - 17:00' },
          { day: 'Friday', time: '08:00 - 16:30' },
          { day: 'Saturday', time: '09:00 - 13:00' },
          { day: 'Sunday', time: 'Closed' },
          { day: 'Public Holidays', time: 'Closed' },
        ],
        mapUrl:
            'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13234.52302989028!2d25.5942206!3d-33.9763289!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e7ad37e1e7ec1ab%3A0xc7b194a5580a16bb!2sHertex%20Fabrics%20Port%20Elizabeth%20Showroom!5e0!3m2!1sen!2sza!4v1718112545747!5m2!1sen!2sza',
      },
    ],
  },
  {
    title: 'Free State',
    stores: [
      {
        title: 'Bloemfontein Showroom',
        address: '11 Brill St, Westdene, Bloemfontein, 9301',
        phone: '051 430 2673',
        holidayNotice: [
          'Closed for the holidays from 14h00 on 13 Dec.',
          'Re-open 6 Jan \'25.'
        ],
        tradingHours: [
          { day: 'Monday - Thursday', time: '08:00 - 17:00' },
          { day: 'Friday', time: '08:00 - 16:30' },
          { day: 'Saturday', time: '09:00 - 13:00' },
          { day: 'Sunday', time: 'Closed' },
          { day: 'Public Holidays', time: 'Closed' },
        ],
        mapUrl:
            'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3486.0312759168514!2d26.214795!3d-29.104758!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e8fc548cb8978c7%3A0xafa11a84a60728e6!2sHertex%20Fabrics%20Bloemfontein%20Showroom!5e0!3m2!1sen!2sza!4v1718112623638!5m2!1sen!2sza',
      },
    ],
  },
  {
    title: 'Gauteng',
    stores: [
      {
        title: 'Fourways Showroom',
        address: '82 The Straight Street, Pineslopes, Fourways Ext 68, 2055',
        phone: '010 276 0100',
        holidayNotice: [
          'Closed for the holidays from 14h00 on 20 Dec.',
          'Re-open 6 Jan \'25.'
        ],
        tradingHours: [
          { day: 'Monday - Thursday', time: '08:00 - 17:00' },
          { day: 'Friday', time: '08:00 - 16:30' },
          { day: 'Saturday', time: '09:00 - 15:00' },
          { day: 'Sunday', time: '10:00 - 14:00' },
          { day: 'Public Holidays', time: 'Closed' },
        ],
        mapUrl:
            'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14341.351260939728!2d28.016603!3d-26.0225007!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9575ea5f3b9ef7%3A0x93a6a886415d08e3!2sHAUS%20Fourways%20Showroom!5e0!3m2!1sen!2sza!4v1721135093028!5m2!1sen!2sza',
      },
      {
        title: 'Kramerville Showroom',
        address: '16 Kramer Rd, Kramerville, Sandton, 2090',
        phone: '011 262 4108',
        holidayNotice: [
          'Closed for the holidays from 14h00 on 13 Dec.',
          'Re-open 6 Jan \'25.'
        ],
        tradingHours: [
          { day: 'Monday - Thursday', time: '08:00 - 17:00' },
          { day: 'Friday', time: '08:00 - 16:30' },
          { day: 'Saturday', time: '09:00 - 14:00' },
          { day: 'Sunday', time: 'Closed' },
          { day: 'Public Holidays', time: 'Closed' },
        ],
        mapUrl:
            'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14333.13276105024!2d28.0849758!3d-26.0896729!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9572c68902ca8b%3A0x67071d34ce35b3f7!2sHertex%20Fabrics%20Kramerville%20Showroom!5e0!3m2!1sen!2sza!4v1718112826419!5m2!1sen!2sza',
      },
      {
        title: 'Parkhurst Showroom',
        address: '74 6th St, Parkhurst, Randburg, 2120',
        phone: '011 025 8483',
        holidayNotice: [
          'Closed for the holidays from 14h00 on 13 Dec.',
          'Re-open 6 Jan \'25.'
        ],
        tradingHours: [
          { day: 'Monday - Thursday', time: '08:00 - 17:00' },
          { day: 'Friday', time: '08:00 - 16:30' },
          { day: 'Saturday', time: '09:00 - 13:00' },
          { day: 'Sunday', time: 'Closed' },
          { day: 'Public Holidays', time: 'Closed' },
        ],
        mapUrl:
            'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14326.46919926357!2d28.0212006!3d-26.1440183!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e950c9dd6a7d595%3A0x49ba81439d4b4c81!2sHertex%20Fabrics%20Parkhurst%20Showroom!5e0!3m2!1sen!2sza!4v1718112875581!5m2!1sen!2sza',
      },
      {
        title: 'HAUS Waterfall Store',
        address: 'Shop 51, Waterfall Corner, Waterfall Dr, Midrand, 2090',
        phone: '010 157 4121',
        tradingHours: [
          { day: 'Monday - Friday', time: '09:00 - 19:00' },
          { day: 'Saturday', time: '09:00 - 17:00' },
          { day: 'Sunday', time: '09:00 - 15:00' },
          { day: 'Public Holidays', time: '09:00 - 15:00' },
          // Added the closed dates here
          { day: 'Closed on 25 Dec, 26 Dec & 1 Jan', time: '' },
        ],
        mapUrl:
            'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14341.396646841295!2d28.0900702!3d-26.0221293!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e95734c7f0246df%3A0xdb6ad960d6ed95f8!2sHaus%20Waterfall%20Retail%20Store!5e0!3m2!1sen!2sza!4v1718115789210!5m2!1sen!2sza',
      },
      {
        title: 'Pretoria Showroom',
        address: '869 Justice Mahomed St, Brooklyn, Pretoria, 0011',
        phone: '012 346 4331',
        holidayNotice: [
          'Closed for the holidays from 14h00 on 13 Dec.',
          'Re-open 6 Jan \'25.'
        ],
        tradingHours: [
          { day: 'Monday - Thursday', time: '08:00 - 17:00' },
          { day: 'Friday', time: '08:00 - 16:30' },
          { day: 'Saturday', time: '09:00 - 13:00' },
          { day: 'Sunday', time: 'Closed' },
          { day: 'Public Holidays', time: 'Closed' },
        ],
        mapUrl:
            'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14372.427587152422!2d28.2343903!3d-25.7670325!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9561a0c7b3f877%3A0x7378c2b36e2d8271!2sHertex%20Fabrics%20Pretoria%20Showroom!5e0!3m2!1sen!2sza!4v1718112930962!5m2!1sen!2sza',
      },
      {
        title: 'HAUS Hazelwood Store',
        address: '8 Hazelwood Rd, Menlo Park, Pretoria, 0081',
        phone: '012 880 1895',
        tradingHours: [
          { day: 'Monday - Friday', time: '09:00 - 18:00' },
          { day: 'Saturday', time: '09:00 - 17:00' },
          { day: 'Sunday', time: '09:00 - 15:00' },
          { day: 'Public Holidays', time: '09:00 - 15:00' },
          // Added the closed dates here
          { day: 'Closed on 25 Dec, 26 Dec & 1 Jan', time: '' },
        ],
        mapUrl:
            'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3592.8635153568025!2d28.2539476!3d-25.7750713!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9561f58de33989%3A0x3e9698b848584144!2sHAUS%20Hazelwood%20Retail%20Store!5e0!3m2!1sen!2sza!4v1718113064429!5m2!1sen!2sza',
      },
    ],
  },
  {
    title: 'KwaZulu Natal',
    stores: [
      {
        title: 'Durban Showroom',
        address: '327 Florida Rd, Morningside, Durban, 4001',
        phone: '031 312 0632',
        holidayNotice: [
          'Closed for the holidays from 14h00 on 13 Dec.',
          'Re-open 6 Jan \'25.'
        ],
        tradingHours: [
          { day: 'Monday - Thursday', time: '08:00 - 17:00' },
          { day: 'Friday', time: '08:00 - 16:30' },
          { day: 'Saturday', time: '09:00 - 13:00' },
          { day: 'Sunday', time: 'Closed' },
          { day: 'Public Holidays', time: 'Closed' },
        ],
        mapUrl:
            'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13844.87079026533!2d31.01119!3d-29.8291374!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ef707721308f983%3A0x8777fbaf7012f952!2sHertex%20Fabrics%20Durban%20Showroom!5e0!3m2!1sen!2sza!4v1718113113909!5m2!1sen!2sza',
      },
      {
        title: 'Umhlanga Showroom',
        address: 'Shop 13, Beacon Rock, 21 Lighthouse Rd, Umhlanga Rocks, uMhlanga, 4319',
        phone: '031 561 8505',
        holidayNotice: [
          'Closed for the holidays from 14h00 on 13 Dec.',
          'Re-open 6 Jan \'25.'
        ],
        tradingHours: [
          { day: 'Monday - Thursday', time: '08:00 - 17:00' },
          { day: 'Friday', time: '08:00 - 16:30' },
          { day: 'Saturday', time: '09:00 - 13:00' },
          { day: 'Sunday', time: 'Closed' },
          { day: 'Public Holidays', time: 'Closed' },
        ],
        mapUrl:
            'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13859.456385958456!2d31.0849593!3d-29.7236957!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ef70f59425bb075%3A0xba7d02b80feca15e!2sHertex%20Fabrics%20Umhlanga%20Showroom!5e0!3m2!1sen!2sza!4v1718113168035!5m2!1sen!2sza',
      },
    ],
  },
  {
    title: 'Mpumalanga',
    stores: [
      {
        title: 'Nelspruit Showroom',
        address: 'Unit 2, Lifestyle Centre, 6 Amanzi St, Riverside park, Mbombela, 1200',
        phone: '013 757 0556',
        holidayNotice: [
          'Closed for the holidays from 14h00 on 13 Dec.',
          'Re-open 6 Jan \'25.'
        ],
        tradingHours: [
          { day: 'Monday - Thursday', time: '08:00 - 17:00' },
          { day: 'Friday', time: '08:00 - 16:30' },
          { day: 'Saturday', time: '09:00 - 13:00' },
          { day: 'Sunday', time: 'Closed' },
          { day: 'Public Holidays', time: 'Closed' },
        ],
        mapUrl:
            'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14411.209310029168!2d30.9604809!3d-25.4448714!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ee84a1f762f04fb%3A0x12d53aa9c4d98722!2sHertex%20Fabrics%20Nelspruit%20Showroom!5e0!3m2!1sen!2sza!4v1718113221184!5m2!1sen!2sza',
      },
    ],
  },
  {
    title: 'Namibia',
    stores: [
      {
        title: 'Windhoek Showroom',
        address: 'Unit 2, City Plaza, Sam Nujoma Street, Windhoek, Namibia',
        phone: '+264 61 219 5600',
        holidayNotice: [
          'Closed for the holidays from 14h00 on 13 Dec.',
          'Re-open 6 Jan \'25.'
        ],
        tradingHours: [
          { day: 'Monday - Friday', time: '08:00 - 17:00' },
          { day: 'First Saturday/ Month', time: '09:00 - 12:00' },
          { day: 'Sunday', time: 'Closed' },
          { day: 'Public Holidays', time: 'Closed' },
        ],
        mapUrl:
            'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14736.774673574415!2d17.1046387!3d-22.5718588!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1c0b1bb6272d81ef%3A0x85165f9efab69184!2sHertex%20Fabrics%20Windhoek%20Showroom!5e0!3m2!1sen!2sza!4v1718113269580!5m2!1sen!2sza',
      },
    ],
  },
  {
    title: 'Mauritius',
    stores: [
      {
        title: 'Haus Hertex Mauritius',
        address: 'MU, Twenty-Foot Rd, Grand Baie 30513, Mauritius',
        phone: '+230 269 0200',
        tradingHours: [
          { day: 'Monday - Friday', time: '09:30 - 17:30' },
          { day: 'Saturday', time: '09:00 - 15:30' },
          { day: 'Sunday', time: 'Closed' },
          { day: 'Public Holidays', time: 'Closed' },
        ],
        mapUrl:
            'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14995.867435406066!2d57.5958485!3d-20.0099056!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x217dab6d1584f1d1%3A0xfd4529ed41c09b0b!2sDeco%20Design%20Haus%20Hertex%20Mauritius!5e0!3m2!1sen!2sza!4v1718115514424!5m2!1sen!2sza',
      },
    ],
  },
];
