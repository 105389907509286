import CarouselBanner from '../../components/shared/banner/CarouselBanner';
import { useEffect } from 'react';
import { catalogueData } from '../../api/tempData';
import CatalogueGrid from '../../components/shared/grid/CatalogueGrid';
import Carlisle1 from '../../assets/internationals/imageCards/Carlisle1.jpg';
import clarke1 from '../../assets/internationals/imageCards/clarke1.jpg';
import editeur1 from '../../assets/internationals/imageCards/editeur1.jpg';
import harlequin1 from '../../assets/internationals/imageCards/harlequin1.jpg';
import masureel1 from '../../assets/internationals/imageCards/masureel1.jpg';
import sanderson1 from '../../assets/internationals/imageCards/sanderson1.jpg';
import scott_living from '../../assets/internationals/imageCards/scott_living.jpg';
import york1 from '../../assets/internationals/imageCards/york1.jpg';
import brewsters_twine from '../../assets/internationals/imageCards/brewsters_twine.jpg';
import TextHeader from '../../components/textHeader/TextHeader';
import ImageCards from '../../components/shared/internationals/ImageCards';
import internationals_hero1 from '../../assets/internationals/heroCarousel/InternationalsHero1.jpg';
import internationals_hero2 from '../../assets/internationals/heroCarousel/InternationalsHero2.jpg';
import internationals_hero3 from '../../assets/internationals/heroCarousel/InternationalsHero3.jpg';
import internationals_hero4 from '../../assets/internationals/heroCarousel/InternationalsHero4.jpg';
import { useLocation } from 'react-router-dom';
import { captureAnalytics } from '../../utilities/Analytics';

export const carouselImages = [
  {
    url: internationals_hero1,
  },
  {
    url: internationals_hero2,
  },
  {
    url: internationals_hero3,
  },
  {
    url: internationals_hero4,
  },
];

const internationalsAssets = [
  {
    img: clarke1,
    title: 'clarke & clarke',
    url: 'https://clarke-clarke.sandersondesigngroup.com/',
  },
  {
    img: Carlisle1,
    title: 'carlisle & co',
    url: 'https://www.carlisleco.com/',
  },
  {
    img: editeur1,
    title: 'editeur',
    url: `${window.location.origin}/item_group/9000/brand/Editeur`,
  },
  {
    img: harlequin1,
    title: 'harlequin',
    url: 'https://harlequin.sandersondesigngroup.com/',
  },
  { img: masureel1, title: 'masureel', url: 'https://masureel.com/en/' },
  {
    img: sanderson1,
    title: 'sanderson',
    url: 'https://sanderson.sandersondesigngroup.com/',
  },
  {
    img: york1,
    title: 'york',
    url: 'https://www.yorkwallcoverings.com/',
  },
  {
    img: scott_living,
    title: 'scott living',
    url: 'https://www.astreetprints.com/scott-living-wallpaper-2-collection-for-a-street-prints#/specFilters=23m!#-!940',
  },
  {
    img: brewsters_twine,
    title: 'brewster',
    url: 'https://www.brewsterwallcovering.com/',
  },
];

function InternationalsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();

  useEffect(() => {
    captureAnalytics('pageview', location.pathname, 'Internationals');
  }, [location.pathname]);

  return (
    <>
      <CarouselBanner images={carouselImages} hasVideo={false} />

      <TextHeader
        heading={'Hertex Internationals'}
        content={
          'Imported from Europe, the UK and the USA and available exclusively from Hertex, these international fabric and wallpaper brands are a lesson in luxury.'
        }
        isCentered={false}
        width="70%"
        marginTop={10}
        marginBottom={5}
      />

      <ImageCards images={internationalsAssets} hasBackgroundText={true} />

      <CatalogueGrid
        header="View Our Latest Catalogues"
        catalogueData={catalogueData}
      />
    </>
  );
}

export default InternationalsPage;
