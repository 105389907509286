import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';

interface FilterElement {
    idx: number | string;
    [key: string]: any;
}

interface FilterProps {
    filterType: FilterElement[];
    handleCheckboxChange: (
        elem: string | number,
        setList: React.Dispatch<React.SetStateAction<string[]>>,
        list: string[],
        paramKey: string
    ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    list: string[];
    setList: React.Dispatch<React.SetStateAction<string[]>>;
    keyName: string;
    paramKey: string; // Add paramKey prop
}

const Filter = ({
                    filterType,
                    handleCheckboxChange,
                    list,
                    setList,
                    keyName,
                    paramKey,
                }: FilterProps) => {
    return (
        <Box
            sx={{
                overflowY: 'auto',
                maxHeight: '20.5rem',
            }}
        >
            <FormGroup>
                {filterType
                    .slice()
                    .sort((a, b) => {
                        const aValue = a[keyName] ? a[keyName].toString() : '';
                        const bValue = b[keyName] ? b[keyName].toString() : '';
                        return aValue.localeCompare(bValue);
                    })
                    .map((elem, index) => {
                        const elemIdxStr = elem.idx.toString();
                        const isChecked = list.includes(elemIdxStr);

                        return (
                            <FormControlLabel
                                key={elem.idx || index}
                                control={
                                    <Checkbox
                                        checked={isChecked}
                                        onChange={handleCheckboxChange(
                                            elem.idx,
                                            setList,
                                            list,
                                            paramKey // Pass paramKey here
                                        )}
                                    />
                                }
                                label={elem[keyName] ? elem[keyName] : 'Unknown'}
                            />
                        );
                    })}
            </FormGroup>
        </Box>
    );
};

export default Filter;
